import { memo, useCallback, useEffect } from 'react';
import AuthWrapper from '../common/auth-wrapper';
import tw from 'twin.macro';
import { getAssetSrc, useAnalytics } from '@restworld/utils-common';
import { useAccountsService } from '@restworld/data-services';
import { AxiosError, AxiosResponse } from 'axios';
import { useAuthContext } from '@restworld/feature-authentication';
import { LoginByEmailForm, LoginByEmailHeader, MagicLinkExpired, MagicLinkUsedHeader } from './login-by-email-form';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  ContentType,
  activeContent,
  existingPhoneNumber as existingPhoneNumberAtom,
  setActiveContent,
} from '../../context/auth-flow';
import { LoginLinkSentForm, LoginLinkSentHeader } from './login-link-sent-form';
import { useEmailLoginForm } from './hooks/use-email-login-form';
import { LoginByPhoneForm, LoginByPhoneHeader } from './login-by-phone-form';
import { VerifyOtpForm, VerifyOtpHeader } from './verify-otp-form';
import { useRouter } from 'next/router';
import { Loader } from '../common';

export const LoginDesktopComponent = memo(({ token, redirectUrl, email }: LoginDesktopComponentProps) => {
  const acc = useAccountsService();
  const authContext = useAuthContext();
  const activeContentType = useAtomValue(activeContent);
  const setActiveContentType = useSetAtom(setActiveContent);
  const { setLoading: setEmailVerificationLoading } = useEmailLoginForm({
    isModal: false,
    currentUrl: redirectUrl,
  });
  const isEmailLogin = activeContentType === ContentType.EMAIL_LOGIN;
  const isEmailSent = activeContentType === ContentType.EMAIL_SENT;
  const isPhoneLogin = activeContentType === ContentType.PHONE_NUMBER_LOGIN;
  const isVerifyOtp = activeContentType === ContentType.OTP_VERIFICATION;
  const linkHasExpired = activeContentType === ContentType.MAGIC_LINK_EXPIRED;
  const linkUsed = activeContentType === ContentType.MAGIC_LINK_USED;
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const action = router.query?.action;
  const job_position_id = router.query?.job_position_id;
  const worker_id = router.query?.worker_id;
  const alreadyAuthenticated = authContext?.isAuthenticated;
  const [, setExistingPhoneNumber] = useAtom(existingPhoneNumberAtom);
  const { doesNumberExistInURL, phoneNumberFromURL } = usePhoneNumberFromURL();

  useEffect(() => {
    if (doesNumberExistInURL) {
      setActiveContentType(ContentType.PHONE_NUMBER_LOGIN);
      setExistingPhoneNumber({ phoneNumber: phoneNumberFromURL });
    }
  }, [doesNumberExistInURL, phoneNumberFromURL, setActiveContentType, setExistingPhoneNumber]);
  const removeFromUrl = useCallback(
    ({ removeAllParams = false }: { removeAllParams?: boolean }) => {
      const { pathname, query } = router;
      if (query?.token) {
        delete query['token'];
      }
      router.replace(
        {
          pathname: pathname,
          query: !removeAllParams ? query : undefined,
        },
        undefined,
        { shallow: true }
      );
    },
    [router]
  );

  const handleVerification = useCallback(
    (token: string) => {
      setEmailVerificationLoading(true);
      acc
        .verifyMagicLink(token)
        .then((response: AxiosResponse) => {
          setEmailVerificationLoading(false);
          const jwtToken = response?.data?.jwt || null;
          if (authContext?.verifyToken) {
            authContext.initializeAuthentication(jwtToken).then(() => removeFromUrl({ removeAllParams: false }));
            // if (alreadyAuthenticated) {
            //   window.location.reload();
            // }
          }
          // trackEvent('Auth flow', {
          //   step: 'Email login link validated successfully',
          //   mode: 'page',
          // });
          trackEvent('complete_signin', {
            method: 'email',
            email: authContext?.user?.email,
            user_id: authContext?.user?.id,
          });
        })
        .catch((error: AxiosError) => {
          setEmailVerificationLoading(false);
          if (!alreadyAuthenticated) {
            const statusCode = parseInt(error.code);
            if (statusCode === 401 || statusCode === 404 || statusCode === 400) {
              setActiveContentType(ContentType.MAGIC_LINK_EXPIRED);
              // trackEvent('Auth flow', {
              //   step: 'Email login link expired',
              //   mode: 'page',
              //   type: 'error',
              // });
            } else if (statusCode === 409) {
              setActiveContentType(ContentType.MAGIC_LINK_USED);
              // trackEvent('Auth flow', {
              //   step: 'Email login link already used',
              //   mode: 'page',
              //   type: 'error',
              // });
            } else {
              setActiveContentType(ContentType.EMAIL_LOGIN);
            }
          }
          removeFromUrl({ removeAllParams: alreadyAuthenticated });
        });
    },
    [
      acc,
      alreadyAuthenticated,
      authContext,
      removeFromUrl,
      setActiveContentType,
      setEmailVerificationLoading,
      trackEvent,
    ]
  );

  useEffect(() => {
    if (token) {
      if (action && job_position_id && worker_id && alreadyAuthenticated) {
        removeFromUrl({ removeAllParams: false });
      } else {
        handleVerification(token);
      }
    }
  }, [action, alreadyAuthenticated, handleVerification, job_position_id, removeFromUrl, token, worker_id]);

  const showBackButton = () => {
    if (isVerifyOtp) {
      return true;
    }
    return false;
  };

  const onBack = () => {
    if (isVerifyOtp) {
      setActiveContentType(ContentType.PHONE_NUMBER_LOGIN);
    }
  };

  useEffect(() => {
    if (!isEmailLogin && !isEmailSent && !isPhoneLogin && !isVerifyOtp && !linkHasExpired && !linkUsed) {
      setActiveContentType(ContentType.EMAIL_LOGIN);
      // trackEvent('Auth flow', { phase: 'Email login', step: 'Start email login', mode: 'page' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (!!token && token !== '') return <Loader />;

  return (
    <AuthWrapper
      showMobileImageOverlay={isEmailLogin}
      showBackButton={showBackButton() ? true : false}
      containerStyles={[tw`lg:pt-37.75 lg:pl-47 lg:pr-38.75 px-6 py-6`, isEmailSent && tw`lg:pt-4`]}
      backgroundImage={getAssetSrc('/utils-assets/chef.png')}
      onBack={onBack}
    >
      <AuthWrapper.Title styles={[tw`lg:mb-0 mb-6.75`]}>
        {isEmailLogin && <LoginByEmailHeader isModal={false} />}
        {isEmailSent && <LoginLinkSentHeader isModal={false} />}
        {isPhoneLogin && <LoginByPhoneHeader isModal={false} />}
        {isVerifyOtp && <VerifyOtpHeader isModal={false} />}
        {linkHasExpired && <MagicLinkExpired isModal={false} />}
        {linkUsed && <MagicLinkUsedHeader isModal={false} />}
      </AuthWrapper.Title>
      <AuthWrapper.Body styles={[tw`lg:mt-10 mt-4`]}>
        {(isEmailLogin || linkHasExpired || linkUsed) && (
          <LoginByEmailForm
            {...{
              isModal: false,
              activeUrl: redirectUrl,
              email,
            }}
          />
        )}
        {isEmailSent && (
          <LoginLinkSentForm
            isModal={false}
            onAcknowledgingEmailReception={() => {
              /**To */
            }}
          />
        )}
        {isPhoneLogin && <LoginByPhoneForm isModal={false} />}
        {isVerifyOtp && <VerifyOtpForm isModal={false} />}
      </AuthWrapper.Body>
    </AuthWrapper>
  );
});

export interface LoginDesktopComponentProps {
  children?: React.ReactNode;
  redirectToPath: (path: string, query?: { [key: string]: string | boolean }) => void;
  token?: string;
  redirectUrl?: string;
  email?: string;
}
export const usePhoneNumberFromURL = () => {
  const router = useRouter();
  const phoneNumberFromURL = router.query?.phone_number as string;
  const doesNumberExistInURL = !!phoneNumberFromURL && phoneNumberFromURL.length > 5;
  return { doesNumberExistInURL, phoneNumberFromURL };
};
