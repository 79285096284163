import React from 'react';
import { BackIcon, customImageLoader, getAssetSrc, useResponsiveBreakPoints } from '@restworld/utils-common';
import tw, { TwStyle, styled } from 'twin.macro';

import { ImpressionIllustration, NavigationBar } from '@restworld/ui-ds';

import Image from 'next/image';

export interface AuthWrapperProps {
  children?: React.ReactNode;
  containerStyles?: TwStyle[];
  backgroundImage?: string;
  showMobileImageOverlay?: boolean;
  showBackButton?: boolean;
  onBack?: () => void;
}

const AuthWrapper = ({
  children,
  containerStyles,
  backgroundImage = getAssetSrc('/utils-assets/restaurant-front.png'),
  showMobileImageOverlay = true,
  showBackButton = false,
  onBack,
}: AuthWrapperProps) => {
  const { isDesktop } = useResponsiveBreakPoints();
  let title = null;
  let body = null;
  /**
   *  Extracting children in order to ensure the title will always be at the top of the page before the overlay image.
  Also to ensure the body appears after the overlay image.
   */
  React.Children.forEach(children, (child: React.ReactElement) => {
    if (child.type === AuthWrapper.Body) {
      body = child;
    }
    if (child.type === AuthWrapper.Title) {
      title = child;
    }
  });
  return (
    <MainContainer isDesktop={isDesktop}>
      <div tw="w-full">
        <NavigationBar
          isMobileDropdown
          justify="between"
          navElement={<></>}
          position="relative"
          background="white"
          logo={{ width: isDesktop ? 139.5 : 124, height: isDesktop ? 36 : 32 }}
        />
      </div>
      <Body>
        <LeftPanel containerStyles={containerStyles}>
          {showBackButton && onBack && (
            <div tw="mb-4 lg:mb-10 hover:cursor-pointer" onClick={onBack}>
              {' '}
              <BackIcon />
            </div>
          )}
          {title}
          {showMobileImageOverlay ? (
            <div tw="lg:hidden w-full min-h-65.25 h-65.25">
              <ImpressionIllustration variant="mobileImageOverlay">
                <BackgroundImage src={backgroundImage} alt="Restaurant" fill={true} loader={customImageLoader} />
              </ImpressionIllustration>
            </div>
          ) : null}
          {body}
        </LeftPanel>
        <RightPanel>
          <ImpressionIllustration variant="imageOverlay">
            <BackgroundImage src={backgroundImage} alt="Restaurant" fill={true} loader={customImageLoader} />
          </ImpressionIllustration>
        </RightPanel>
      </Body>
    </MainContainer>
  );
};

const AuthWrapperTitle = ({ children, styles }: { children: React.ReactNode; styles?: TwStyle[] }) => {
  return <StyledAuthWrapperTitle styles={styles}>{children}</StyledAuthWrapperTitle>;
};

const AuthWrapperBody = ({ children, styles }: { children: React.ReactNode; styles?: TwStyle[] }) => {
  return <StyledAuthWrapperBody styles={styles}>{children}</StyledAuthWrapperBody>;
};

AuthWrapper.Title = AuthWrapperTitle;
AuthWrapper.Body = AuthWrapperBody;

export default AuthWrapper;

const MainContainer = styled.div<{ isDesktop?: boolean }>(({ isDesktop }) => [
  tw`flex flex-1 flex-col items-start justify-start w-full  box-border h-[100vh] max-w-[100vw]`,
  isDesktop && tw`max-h-[100vh] overflow-hidden`,
]);

// const Header = styled.div(() => [
//   tw`flex flex-col w-full items-start justify-start lg:px-20 lg:pt-6 lg:pb-10 px-6 pt-12 pb-6`,
// ]);

const Body = styled.div(() => [tw`flex flex-row gap-0 w-full h-full`]);

const LeftPanel = styled.div<{ containerStyles?: TwStyle[] }>(({ containerStyles }) => [
  tw`flex flex-col justify-start items-start box-border lg:w-[47.125rem] lg:max-w-[47.125rem] w-full
  lg:h-[calc(100vh-158px)] h-[calc(100vh-192px)] overflow-y-auto`,
  tw`lg:pr-33.75  lg:pt-16.25 lg:pl-47 lg:pb-16`,
  tw`pl-6 pr-6 pt-9.25 pb-23.25`,
  ...(containerStyles || []),
]);

const RightPanel = styled.div(() => [
  tw`relative box-border lg:flex  lg:flex-1 !max-h-full  items-start justify-start hidden`,
]);
const BackgroundImage = styled(Image)(() => [
  tw`object-cover lg:rounded-none lg:rounded-bl-6.25 lg:rounded-tl-6.25 rounded-2xl `,
]);

const StyledAuthWrapperTitle = styled.div<{ styles?: TwStyle[] }>(({ styles }) => [
  tw`flex flex-col w-full justify-start items-start`,
  ...(styles || []),
]);

const StyledAuthWrapperBody = styled.div<{ styles?: TwStyle[] }>(({ styles }) => [
  tw`flex flex-col w-full justify-start items-start`,
  ...(styles || []),
]);
