import { LoginDesktopComponent } from '../../components/Login/login-desktop';
import { LayoutWrapper } from '../../layouts';
import { useRouter } from 'next/router';
import { getServerTranslations } from '../../lib/i18n/getServerTranslations';

const LoginDesktopPage = () => {
  const router = useRouter();
  const redirectToPath = (path: string, query?: { [key: string]: string | boolean }) => {
    router.push({
      pathname: path,
      query: query,
    });
  };
  const token = router?.query?.token?.toString() || '';
  const email = router?.query?.email?.toString() || undefined;
  let redirectUrl = router?.query?.redirect_url?.toString();

  if (router.query?.redirectTo) {
    const redirectToLogin =
      router.query?.redirectTo &&
      (Array.isArray(router.query.redirectTo)
        ? decodeURIComponent(router.query.redirectTo[0])
        : decodeURIComponent(router.query.redirectTo));
    const redirectParamsLogin =
      router.query?.redirectParams &&
      (Array.isArray(router.query.redirectParams)
        ? JSON.parse(decodeURIComponent(router.query.redirectParams[0]))
        : JSON.parse(decodeURIComponent(router.query.redirectParams)));
    if (!!redirectToLogin && !!redirectParamsLogin) {
      redirectUrl = `${redirectToLogin}?${new URLSearchParams(redirectParamsLogin)}`;
    } else if (redirectToLogin) {
      redirectUrl = redirectToLogin;
    }
  }

  return (
    <LayoutWrapper
      title={'Entra in Restworld'}
      showSearchBarInNavbar={false}
      noHorizontalPadding
      noPaddingBottom
      noPaddingTop
    >
      <LoginDesktopComponent {...{ redirectUrl, redirectToPath, token, email }} />
    </LayoutWrapper>
  );
};

export const getStaticProps = async ({ locale }) => {
  const transConfig = await getServerTranslations(locale, ['common', 'auth-flow']);
  return {
    props: {
      ...transConfig,
    },
  };
};

export default LoginDesktopPage;
